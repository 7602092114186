<script setup lang="ts">
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useUserEvents } from '/~/composables/user'
import { usePointsPrograms } from '/~/templates/bill-payments/composables'

const { termsAndConditionsVisited } = useUserEvents()
const { selectedPartnersTerms } = usePointsPrograms()

termsAndConditionsVisited({
  type: 'modal',
  name: 'bill payments points transfer terms modal',
})
</script>

<template>
  <base-mdl width="lg" fullscreen="mobile">
    <div
      class="ql-editor ql-show"
      v-html="selectedPartnersTerms.terms_and_conditions.textarea"
    />

    <template #footer>
      <div class="flex w-full items-center justify-center pt-5">
        <base-button class="mx-2.5 w-1/2" color="primary" @click="modal.hide()">
          Got it
        </base-button>
      </div>
    </template>
  </base-mdl>
</template>
